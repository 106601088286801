import React , { Component } from 'react'
import { NavLink as NavLinkRouter } from 'react-router-dom'
import { 
  Navbar,
  NavbarBrand,
  Nav,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink
} from 'reactstrap'

// assets 
import {ReactComponent as Logo} from '../../Assets/logo.svg'
import Strings from '../../Assets/Strings.json'

// styles
import './Header.scss'


export default class Header extends Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    if(window.matchMedia('(max-width: 540px)').matches){
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }))
    }
  }

  render() {
    return (
      <Navbar light expand="md">
        <NavbarBrand tag={NavLinkRouter} onClick={() => {
          if(window.matchMedia('(max-width: 540px)').matches){
            this.setState({
              isOpen: false
            })
          }
        }} to="/"><Logo /></NavbarBrand>
        <NavbarToggler onClick={this.toggle} className={this.state.isOpen ? 'open' : null} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className='main-nav' navbar>
            <NavItem>
              <NavLink tag={NavLinkRouter} to="/vare-bilder" onClick={this.toggle} activeClassName="active">{Strings['vare-bilder']}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={NavLinkRouter} to="/om-oss" className="om-oss-link" onClick={this.toggle} activeClassName="active">{Strings['om-oss']}</NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink tag={NavLinkRouter} to="/kontakt" onClick={this.toggle} activeClassName="active">{Strings['kontakt']}</NavLink>
            </NavItem>
            {/* <NavItem className="social">
              <Nav>
                <NavItem>
                  <NavLink href="https://facebook.com" target="_blank">Fb</NavLink> 
                </NavItem>
                <NavItem>
                  <NavLink href="https://instagram.com" target="_blank">In</NavLink>
                </NavItem>
              </Nav>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}
