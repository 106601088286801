import React from 'react'
import {Picture} from 'react-responsive-picture'

// components
import Spinner from '../Spinner/Spinner'

// styles
import './Picture.scss'

const mediaQ = [
  '(min-width: 1920px)',
  '(min-width: 1280px)',
  '(min-width: 640px)',
  '(min-width: 0px)'
]

export default (props) => {
  const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''
  const sources = [
    `${url}/images/${encodeURI(props.sources.replace('.jpg', '@1x.jpg'))}`,
    `${url}/images/${encodeURI(props.sources.replace('.jpg', '@0,75x.jpg'))}`,
    `${url}/images/${encodeURI(props.sources.replace('.jpg', '@0,5x.jpg'))}`,
    `${url}/images/${encodeURI(props.sources.replace('.jpg', '@0,25x.jpg'))}`
  ]
  const sourcesObj = sources.map((srcSet, i) => {
    const source = {srcSet}
    source.media = mediaQ[i]

    return source
  })

  if(props.lazy){
    return (
      <div className="lazy-wrapper">
        <picture className={props.className}>
          {sourcesObj.map(source => <source
              data-srcset={source.srcSet}
              key={source.media}
              media={source.media} />)}
          <img
              src={sourcesObj[sourcesObj.length-1].srcSet}
              className="lazyload blur-up"
              alt={props.alt} />
          <Spinner loading={true} />
        </picture>
      </div>
    )
  }

  return <Picture sources={sourcesObj} className={this.props.className} alt={this.props.alt} />
}
