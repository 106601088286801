import React, { Component } from 'react'
import { Col } from 'reactstrap'
import {TweenMax, TimelineMax, Power4} from 'gsap/TweenMax'

// hoc
import animation from '../../hoc/Animation'

// components
import Picture from '../../components/Picture/Picture'

// assets
import Strings from '../../Assets/Strings.json'

// styles
import './Contacts.scss'

// images
import imgArr from '../../Assets/images.json'

class Contacts extends Component {
  state = {
    image: imgArr[Math.floor(Math.random() * imgArr.length)]
  }
  render() {
    return (
      <article className='page-content about container'>
        <header className="row no-gutters" ref={el => this.header = el}>
          <Col className="header-text" xl={{size: 6}}>
            <h1 className='small-header'>{ Strings.contact }</h1>
            <div className="text" dangerouslySetInnerHTML={ {__html:Strings.contactsText} } />
            <div className="small-text" dangerouslySetInnerHTML={{__html: Strings.contactInfo}} />
          </Col>
          <Col className='header-pic' xl={{size: 5, offset:1}}>
            <Picture sources={this.state.image} alt="Om oss header image" lazy={true} />
          </Col>
        </header>
      </article>
    )
  }
}

const animateIn = (node, done) => {
  const timeline = new TimelineMax({delay: 0.6, onComplete: done})

  const headerTween = TweenMax.staggerFrom(node.querySelector('header').childNodes, 0.3, {
    y: 150,
    opacity: 0,
    ease: Power4.easeOut
  }, 0.03)

  timeline.add([headerTween])
}

export default animation(Contacts, animateIn)