import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import Carousel from 'react-slick'
import {TweenMax, Power2, TimelineMax, Power4} from 'gsap/TweenMax'

// hoc
import animation from '../../hoc/Animation'

// components
import Picture from '../../components/Picture/Picture'

// assets
import Strings from '../../Assets/Strings.json'
import {ReactComponent as Left} from '../../Assets/left-arrow.svg'
import {ReactComponent as Right} from '../../Assets/right-arrow.svg'

// styles
import './Home.scss'

// images
import imgArr from '../../Assets/sliderImages.json'

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  className: 'home-slider'
};

class Home extends Component {
  state = {
    images: imgArr
  }
  slider = null

  getRandom(arr, n) {
      const result = new Array(n)
      let len = arr.length
      const taken = new Array(len)
      if (n > len)
          throw new RangeError("getRandom: more elements taken than available")
      while (n--) {
          var x = Math.floor(Math.random() * len)
          result[n] = arr[x in taken ? taken[x] : x]
          taken[x] = --len in taken ? taken[len] : len
      }

      return result
  }

  render() {
    window.slider = this.slider
    return (
      <article className='page-content home row align-items-end'>
        <header className="col-xl-5 col-sm-10">
          <h1 className='small-header'>{ Strings.welcome }</h1>
          <div className="text" dangerouslySetInnerHTML={ {__html:Strings.homeText} } />
          <div className="header-nav">
            <Nav className="page-nav">
              <NavItem>
                <NavLink tag={Link} to='/vare-bilder'>{ Strings['vare-bilder'] }</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/om-oss'>{ Strings['om-oss'] }</NavLink>
              </NavItem>
            </Nav>

            <Nav className="md-auto slider-controls">
              <NavItem>
                <NavLink onClick={() => this.slider.slickPrev() } className='left-link'><Left /></NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => this.slider.slickNext() } className='right-link'><Right /></NavLink>
              </NavItem>
            </Nav>
          </div>
        </header>
        <Carousel ref={el => this.slider = el} {...sliderSettings}>
          {this.state.images.map(img => <Picture sources={img} alt="Home slider image" lazy={true} key={img} />)}
        </Carousel>
      </article>
    )
  }
}

const animateIn = (node, done) => {
  const timeline = new TimelineMax({delay: 0.6, onComplete: done })

  const headerTween = TweenMax.from(node.querySelector('header'), 0.3, {
    y: '120%',
    ease: Power4.easeOut
  })

  const sliderTween = TweenMax.from(node.querySelector('.home-slider'), 0.6, {
    opacity: 0,
    ease: Power2.easeOut
  })

  timeline.add([sliderTween, headerTween])
}

export default animation(Home, animateIn)