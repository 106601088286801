import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import {TweenMax, TimelineMax, Power4} from "gsap/TweenMax"

// hoc
import animation from '../../hoc/Animation'

// components
import Picture from '../../components/Picture/Picture'
// import Person from '../../components/Person/Person'

// assets
import Strings from '../../Assets/Strings.json'
// import People from '../../Assets/people.json'

// styles
import './About.scss'


// portrait pics
// import PersonImg from '../../Assets/people/person.jpg'

// images
import imgArr from '../../Assets/images.json'

class About extends Component {
  state = {
    image: imgArr[Math.floor(Math.random() * imgArr.length)]
  }
  render() {
    return (
      <article className='page-content about container'>
        <header className="row no-gutters">
          <Col className="header-text" xl={{size: 6}}>
            <h1 className='small-header'>{ Strings.vision }</h1>
            <div className="text" dangerouslySetInnerHTML={ {__html:Strings.visionText} } />
          </Col>
          <Col className='header-pic' xl={{size: 5, offset:1}}>
            <Picture sources={this.state.image} alt="Om oss header image" lazy={true} />
            <div className="om-oss-text">
              <h2 className='small-header'>{ Strings['om-oss'] }</h2>
              <div className='text' dangerouslySetInnerHTML={ {__html:Strings.aboutText} } />
            </div>
          </Col>
        </header>
        {/* <div className='people'>
          <h3 className='small-header row'>{ Strings.photographer }</h3>
          <Row noGutters={true}>
            {People.map(person => {
              person.img = PersonImg
              return <Person {...person} key={person.name} />
            })}
          </Row>
        </div> */}
      </article>
    )
  }
}

const animateIn = (node, done) => {
  const timeline = new TimelineMax({delay: 0.6, onCompete: done})

  const headerTween = TweenMax.staggerFrom(node.querySelector('header').childNodes, 0.3, {
    y: 150,
    opacity: 0,
    ease: Power4.easeOut
  }, 0.03)

  const peopleTween = TweenMax.staggerFrom(node.querySelectorAll('.people .small-header, .person'), 0.3, {
    y: 150,
    opacity: 0,
    ease: Power4.easeOut
  }, 0.03)

  timeline.add([headerTween, peopleTween])
}

export default animation(About, animateIn)
