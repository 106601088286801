import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import 'lazysizes'


// container
import Container from './containers/Container/Container'

// components
import Spinner from './components/Spinner/Spinner'
import Header from './components/Header/Header'

// styles
import './App.scss'

class App extends Component {
  state = {
    loaded: false
  }
  componentDidMount() {
    window.lazySizesConfig = window.lazySizesConfig || {}
    window.lazySizesConfig.loadMode = 3

    window.addEventListener('load', this.loadHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.loadHandler)
  }

  loadHandler = () => {
    window.loaded = true
    this.setState({loaded:true})
  }

  render() {
    let content = <Spinner loading={true} />

    if(this.state.loaded){
      content = (
        <main className="container-fluid">
          <Header />
          <Container />
        </main>
      )
    }
    return (
      <Router>
        {content}
      </Router>
    );
  }
}

export default App
