import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { TweenMax, Power4 } from 'gsap/TweenMax'

// containers
import Home from '../Home/Home'
import About from '../About/About'
import Gallery from '../Gallery/Gallery'
import Contacts from '../Contacts/Contacts'
import NotFound from '../NotFound/NotFound'

// hoc
import withTracker from '../../hoc/GA'

// styles
import './Container.scss'

class Container extends Component {
  navTween = null

  componentDidMount() {
    this.navTween = TweenMax.from(document.querySelector('.navbar-expand-md'), 0.3, {
      y: '-100%',
      ease: Power4.easeOut,
      paused: true
    })

    this.navTween.play()
  }

  render() {
    const route = this.props.location.pathname.replace('/','')
    return (
      <div id="content" className={ route ? route + '-route' : 'home-route'}>
        <Route path="/" exact>
          { ({ match }) => <Home show={match !== null} /> }
        </Route>
        <Route path="/om-oss" exact>
          { ({ match }) => <About show={match !== null} /> }
        </Route>
        <Route path="/vare-bilder" exact>
          { ({ match }) => <Gallery show={match !== null} /> }
        </Route>
        <Route path="/kontakt" exact>
          { ({ match }) => <Contacts show={match !== null} /> }
        </Route>
        <Route render={({ location }) => {
          return location.pathname !== '/' &&
                 location.pathname !== '/om-oss' &&
                 location.pathname !== '/vare-bilder' &&
                 location.pathname !== '/kontakt' ? <NotFound show={true} /> : null 
        }} />
      </div>
    )
  }
}

export default withRouter(withTracker(Container))
