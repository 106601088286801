import React from 'react'
import { css } from '@emotion/core'
import { BounceLoader } from 'react-spinners'

const styles = css`
  position: absolute!important;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
`

export default (props) => {
  return (
    <BounceLoader loading={props.loading} color={"#D5CB8B"} css={styles} size={60} sizeUnit={"px"} />
  )
}
