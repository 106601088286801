import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import {TweenMax,  TimelineMax, Power4} from 'gsap/TweenMax'

// hoc
import animation from '../../hoc/Animation'

// components
import Picture from '../../components/Picture/Picture'

// assets
import Strings from '../../Assets/Strings.json'

// styles
import './Gallery.scss'

// images
import imgArr from '../../Assets/images.json'

class Gallery extends Component {
  i = 0

  render() {
    return (
      <article className='page-content gallery'>
        <header className="container" ref={el => this.header = el}>
          <Row className='align-items-end'>
            <Col className="header-text" md={{size: 6}}>
              <h1 className='small-header'>{ Strings['vare-bilder'] }</h1>
              <div className="text" dangerouslySetInnerHTML={ {__html:Strings.galleryText} } />
            </Col>
            <Col className='scroll-more' md={{size: 6}}>
              {Strings.scroll}
            </Col>
          </Row>
        </header>
        <div className="gallery-wrapper">
          {imgArr.map((img, i) => {
            this.i++

            return <Picture sources={img} lazy={true} alt={img.alt} key={this.i} />
          })}
        </div>
      </article>
    )
  }
}

const animateIn = (node, done) => {
  const timeline = new TimelineMax({delay: 0.6, onComplete: done})

  const headerTween = TweenMax.staggerFrom(node.querySelectorAll('header .row>div'), 0.3, {
    x: -150,
    opacity: 0,
    ease: Power4.easeOut
  }, 0.03)

  const galleryTween = TweenMax.from(node.querySelector('.gallery-wrapper'), 0.6, {
    y: 250,
    opacity: 0,
    ease: Power4.easeOut
  })

  timeline.add([headerTween, galleryTween])
}

export default animation(Gallery, animateIn)