import React, { Component } from 'react'
import { Col } from 'reactstrap'
import {TweenMax, TimelineMax, Power4} from 'gsap/TweenMax'
import { Link, withRouter } from 'react-router-dom'

// hoc
import animation from '../../hoc/Animation'

// assets
import Strings from '../../Assets/Strings.json'

class Contacts extends Component {
  render() {
    return (
      <article className='page-content about container'>
        <header className="row no-gutters" ref={el => this.header = el}>
          <Col className="header-text" xl={{size: 6}}>
            <h1 className='small-header'>{ Strings.notFound }</h1>
            <div className="text" dangerouslySetInnerHTML={ {__html:Strings.notFoundText} } />
            <Link to="/">{ Strings.goHome }</Link>
          </Col>
        </header>
      </article>
    )
  }
}

const animateIn = (node, done) => {
  const timeline = new TimelineMax({delay: 0.6, onComplete: done})

  const headerTween = TweenMax.staggerFrom(node.querySelector('header').childNodes, 0.3, {
    y: 150,
    opacity: 0,
    ease: Power4.easeOut
  }, 0.03)

  timeline.add([headerTween])
}

export default withRouter(animation(Contacts, animateIn))