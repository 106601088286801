import React, { Component } from 'react'
import { Transition } from 'react-transition-group'
import {TweenMax, Power4} from 'gsap/TweenMax'

export default (WrappedComponent, animateIn, animateOut) => class AnimatedWrapper extends Component {
  event = new Event('animationEnd')

  enterAnim = node => {
    if(animateIn){
      animateIn(node, () => node.dispatchEvent(this.event))
    }else{
      TweenMax.fromTo(node, 0.6, {
        opacity: 0,
        y: 250,
      },{
        opacity: 1,
        x: 0,
        ease: Power4.easeIn,
        onComplete: () => {
          node.dispatchEvent(this.event)
        }
      })
    }
  }

  endAnim = node => {
    if(animateOut){
      animateOut(node, () => node.dispatchEvent(this.event))
    }else{
      TweenMax.fromTo(node, 0.6, {
        opacity: 1,
        x: 0,
      },{
        opacity: 0,
        y: 250,
        ease: Power4.easeIn,
        onComplete: () => {
          node.dispatchEvent(this.event)
        }
      })
    }
    
  }

  render() {
    return (
      <Transition
        unmountOnExit
        appear
        in={this.props.show}
        onEnter={this.enterAnim}
        onExit={this.endAnim}
        timeout={800}
        addEndListener={(node,done) => {
          node.addEventListener('animationEnd', done, false)
        }}
      >
        {(status) => (
          <div className={`transition-wrapper animation-${status}`}>
            <WrappedComponent {...this.props} />
          </div>
        )}
     </Transition>
    );
   }
}