import React, {Component, Fragment} from 'react'
import GoogleAnalytics from 'react-ga'
import { Helmet } from "react-helmet"

// assets
import Strings from '../Assets/Strings.json'

GoogleAnalytics.initialize('UA-0000000-0')

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page)
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname
      
      trackPage(page)
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      let title = null
      const path = this.props.location.pathname.replace('/','')
      if(path){
        title = Strings[path] ? Strings[path] : Strings.notFound
      }else{
        title = Strings.home
      }

      return (
        <Fragment>
          <Helmet titleTemplate={`%s | ${Strings.siteName}`}>
            <title>{title}</title>
          </Helmet>
          <WrappedComponent {...this.props} />
        </Fragment>
      )
    }
  }

  return HOC
}

export default withTracker